.components-container[data-v-b4e6a6b8]  .form-row.form-group {
  margin-right: -15px;
  margin-left: -15px;
}
.components-container[data-v-b4e6a6b8]  .form-row > .col,
.components-container[data-v-b4e6a6b8]  .form-row > [class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
}
.compact-vertical-form[data-v-b4e6a6b8]  .col-form-label {
  padding-bottom: 0px !important;
}
.plugin-editor-modal[data-v-b4e6a6b8]  .close {
  position: absolute;
  right: 10px;
}
.component-container[data-v-b4e6a6b8]:empty {
  display: none !important;
}